import { RouteRecordRaw } from "vue-router";
import { customerRouters } from "./customerGroup";
import { staffRouters } from "./staffGroup";
import { storeRouters } from "./storeGroup";
import { adminCompanyRouter, adminRouters } from "./adminGroup";
import { companyRouters } from "./companyGroup";

export const Routes: Array<RouteRecordRaw> = [
  customerRouters,
  staffRouters,
  storeRouters,
  companyRouters,
  adminRouters,
  adminCompanyRouter,
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "701" */ "@/views/404Page.vue"),
  },
];
