import PassThroughVue from "@/components/layouts/PassThrough.vue";
import RoutesNames from "./routesNames";
import { ACCOUNT_TYPES } from "@/constants";

const CUSTOMER_ROUTER_NAMES = {
  home: RoutesNames.customer[1],
  login: RoutesNames.customer[0],
  cashRegister: "cashRegister",
  modalCashRegisterSelection: "modalCashRegisterSelection",
  startBilling: "startBilling",
  useOfCoupons: "useOfCoupons",
  couponReading: "couponReading",
  couponSelection: "couponSelection",
  languageSwitching: "languageSwitching",
  modalPutItemToBox: "modalPutItemToBox",
  cart: "cart",
  checkSecondBasket: "checkSecondBasket",
  modalCheckNumberProduct: "modalCheckNumberProduct",
  modalMoveItemYourCart: "modalMoveItemYourCart",
  purchasingBags: "purchasingBags",
  selectNumberBags: "selectNumberBags",
  selectPayment: "selectPayment",
  paymentInCash: "paymentInCash",
  receiptPrinting: "receiptPrinting",
  paymentByCreditCard: "paymentByCreditCard",
  paymentByCreditCardContinue: "paymentByCreditCardContinue",
  paymentByCreditCardProcessing: "paymentByCreditCardProcessing",
  paymentByCreditCardCompleted: "paymentByCreditCardCompleted",
  electronicMoneySelection: "electronicMoneySelection",
  electronicMoneyBalanceInquiry: "electronicMoneyBalanceInquiry",
  electronicMoneyBalanceInquiryContinue:
    "electronicMoneyBalanceInquiryContinue",
  electronicMoneyPayment: "electronicMoneyPayment",
  QRCodePayment: "QRCodePayment",
  QRCodePaymentWith: "QRCodePaymentWith",
  receiptPrintingAllTypes: "receiptPrintingAllTypes",
  paymentCompleted: "paymentCompleted",
  modalRefundError: "modalRefundError",
  modalSystemErrorSalePeriod: "modalSystemErrorSalePeriod",
  modalSystemErrorProhibitedProducts: "modalSystemErrorProhibitedProducts",
  modalSystemErrorOutOfReceiptPaper: "modalSystemErrorOutOfReceiptPaper",
  modalSystemErrorProductScanError: "modalSystemErrorProductScanError",
  modalPaymentErrorDialog: "modalPaymentErrorDialog",
  modalPaymentErrorDialogFraudulentCard:
    "modalPaymentErrorDialogFraudulentCard",
  modalSystemErrorInsufficientElectronicMoneyBalanceError:
    "modalSystemErrorInsufficientElectronicMoneyBalanceError",
  modalSystemErrorRFIDTagWriting: "modalSystemErrorRFIDTagWriting",
};

const customerRouters = {
  path: "/",
  name: "customer_pages",
  component: PassThroughVue,
  children: [
    {
      path: "/",
      meta: {
        anonymousOnly: true,
        redirect: CUSTOMER_ROUTER_NAMES.home
      },
      component: () =>
        import(/* webpackChunkName: "login" */ "@/views/auth/LoginView.vue"),
        // import(/* webpackChunkName: "c011" */ "@/views/client/ViewC011.vue"),
    },
    {
      path: "/login",
      name: CUSTOMER_ROUTER_NAMES.login,
      meta: {
        anonymousOnly: true,
        redirect: CUSTOMER_ROUTER_NAMES.home,
      },
      component: () =>
        import(/* webpackChunkName: "login" */ "@/views/auth/LoginView.vue"),
    },
    {
      path: "cash-register",
      name: CUSTOMER_ROUTER_NAMES.cashRegister,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c001" */ "@/views/client/ViewC001.vue"),
    },
    {
      path: "modal-cash-register-selection",
      name: CUSTOMER_ROUTER_NAMES.modalCashRegisterSelection,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c002" */ "@/views/client/ViewC002.vue"),
    },
    {
      path: "start-billing",
      name: CUSTOMER_ROUTER_NAMES.startBilling,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c011" */ "@/views/client/ViewC011.vue"),
    },
    {
      path: "coupons/use",
      name: CUSTOMER_ROUTER_NAMES.useOfCoupons,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c012" */ "@/views/client/ViewC012.vue"),
    },
    {
      path: "coupons/reading",
      name: CUSTOMER_ROUTER_NAMES.couponReading,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c013" */ "@/views/client/ViewC013.vue"),
    },
    {
      path: "coupons/selection",
      name: CUSTOMER_ROUTER_NAMES.couponSelection,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c014" */ "@/views/client/ViewC014.vue"),
    },
    {
      path: "language-switching",
      name: CUSTOMER_ROUTER_NAMES.languageSwitching,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c015" */ "@/views/client/ViewC015.vue"),
    },
    {
      path: "modal-put-item-to-box",
      name: CUSTOMER_ROUTER_NAMES.modalPutItemToBox,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c021" */ "@/views/client/ViewC021.vue"),
    },
    {
      path: "cart",
      name: CUSTOMER_ROUTER_NAMES.cart,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c022" */ "@/views/client/ViewC022.vue"),
    },
    {
      path: "check-second-basket",
      name: CUSTOMER_ROUTER_NAMES.checkSecondBasket,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c023" */ "@/views/client/ViewC023.vue"),
    },
    {
      path: "modalCheckNumberProduct",
      name: CUSTOMER_ROUTER_NAMES.modalCheckNumberProduct,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c024" */ "@/views/client/ViewC024.vue"),
    },
    {
      path: "modalMoveItemYourCart",
      name: CUSTOMER_ROUTER_NAMES.modalMoveItemYourCart,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c025" */ "@/views/client/ViewC025.vue"),
    },
    {
      path: "purchasing-bags",
      name: CUSTOMER_ROUTER_NAMES.purchasingBags,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c031" */ "@/views/client/ViewC031.vue"),
    },
    {
      path: "select-number-bags",
      name: CUSTOMER_ROUTER_NAMES.selectNumberBags,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c032" */ "@/views/client/ViewC032.vue"),
    },
    {
      path: "select-payment",
      name: CUSTOMER_ROUTER_NAMES.selectPayment,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c041" */ "@/views/client/ViewC041.vue"),
    },
    {
      path: "payment-in-cash",
      name: CUSTOMER_ROUTER_NAMES.paymentInCash,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c042" */ "@/views/client/ViewC042.vue"),
    },
    {
      path: "receipt-printing",
      name: CUSTOMER_ROUTER_NAMES.receiptPrinting,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c043" */ "@/views/client/ViewC043.vue"),
    },
    {
      path: "payment-by-credit-card",
      name: CUSTOMER_ROUTER_NAMES.paymentByCreditCard,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c051" */ "@/views/client/ViewC051.vue"),
    },
    {
      path: "payment-by-credit-card/continue",
      name: CUSTOMER_ROUTER_NAMES.paymentByCreditCardContinue,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c052" */ "@/views/client/ViewC052.vue"),
    },
    {
      path: "payment-by-credit-card/processing",
      name: CUSTOMER_ROUTER_NAMES.paymentByCreditCardProcessing,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c053" */ "@/views/client/ViewC053.vue"),
    },
    {
      path: "payment-by-credit-card/completed",
      name: CUSTOMER_ROUTER_NAMES.paymentByCreditCardCompleted,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c054" */ "@/views/client/ViewC054.vue"),
    },
    {
      path: "electronic-money/selection",
      name: CUSTOMER_ROUTER_NAMES.electronicMoneySelection,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c061" */ "@/views/client/ViewC061.vue"),
    },
    {
      path: "electronic-money/balance-inquiry",
      name: CUSTOMER_ROUTER_NAMES.electronicMoneyBalanceInquiry,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c062" */ "@/views/client/ViewC062.vue"),
    },
    {
      path: "electronic-money/balance-inquiry/continue",
      name: CUSTOMER_ROUTER_NAMES.electronicMoneyBalanceInquiryContinue,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c063" */ "@/views/client/ViewC063.vue"),
    },
    {
      path: "electronic-money/payment",
      name: CUSTOMER_ROUTER_NAMES.electronicMoneyPayment,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c064" */ "@/views/client/ViewC064.vue"),
    },
    {
      path: "qr-code/payment",
      name: CUSTOMER_ROUTER_NAMES.QRCodePayment,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c071" */ "@/views/client/ViewC071.vue"),
    },
    {
      path: "qr-code/payment-with",
      name: CUSTOMER_ROUTER_NAMES.QRCodePaymentWith,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c073" */ "@/views/client/ViewC073.vue"),
    },
    {
      path: "receipt-printing/all-types",
      name: CUSTOMER_ROUTER_NAMES.receiptPrintingAllTypes,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c081" */ "@/views/client/ViewC081.vue"),
    },
    {
      path: "payment/completed",
      name: CUSTOMER_ROUTER_NAMES.paymentCompleted,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "c082" */ "@/views/client/ViewC082.vue"),
    },
    {
      path: "modalRefundError",
      name: CUSTOMER_ROUTER_NAMES.modalRefundError,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "e000" */ "@/views/client/ViewE000.vue"),
    },
    {
      path: "modalSystemError/sale-period",
      name: CUSTOMER_ROUTER_NAMES.modalSystemErrorSalePeriod,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "e001" */ "@/views/client/ViewE001.vue"),
    },
    {
      path: "modalSystemError/prohibited-products",
      name: CUSTOMER_ROUTER_NAMES.modalSystemErrorProhibitedProducts,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "e001-2" */ "@/views/client/ViewE001-2.vue"),
    },
    {
      path: "modalSystemError/out-of-receipt-paper",
      name: CUSTOMER_ROUTER_NAMES.modalSystemErrorOutOfReceiptPaper,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "e002" */ "@/views/client/ViewE002.vue"),
    },
    {
      path: "modalSystemError/product-scan",
      name: CUSTOMER_ROUTER_NAMES.modalSystemErrorProductScanError,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "e003" */ "@/views/client/ViewE003.vue"),
    },
    {
      path: "modalPaymentErrorDialog",
      name: CUSTOMER_ROUTER_NAMES.modalPaymentErrorDialog,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "e004" */ "@/views/client/ViewE004.vue"),
    },
    {
      path: "modalPaymentErrorDialog/fraudulent-card",
      name: CUSTOMER_ROUTER_NAMES.modalPaymentErrorDialogFraudulentCard,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "e005" */ "@/views/client/ViewE005.vue"),
    },
    {
      path: "modalSystemError/insufficient-electronic-money-balance-error",
      name: CUSTOMER_ROUTER_NAMES.modalSystemErrorInsufficientElectronicMoneyBalanceError,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "e006" */ "@/views/client/ViewE006.vue"),
    },
    {
      path: "modalSystemError/RFID-tag-writing",
      name: CUSTOMER_ROUTER_NAMES.modalSystemErrorRFIDTagWriting,
      meta: {
        requiresAuth: true,
        redirect: CUSTOMER_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "e007" */ "@/views/client/ViewE007.vue"),
    }
  ],
};

export { customerRouters, CUSTOMER_ROUTER_NAMES };
