import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrashCan,
  faChevronDown,
  faChevronUp,
  faEye,
  faPlusCircle,
  faEyeSlash,
  faPlus,
  faMinus,
  faUnlock,
  faXmark,
  faEllipsis,
  faCircleXmark
} from "@fortawesome/free-solid-svg-icons";

library.add(faTrashCan, faChevronDown, faChevronUp, faEye, faPlusCircle, faEyeSlash, faPlus, faMinus, faUnlock, faXmark, faEllipsis, faCircleXmark);

export default FontAwesomeIcon;
