import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import FontAwesomeIcon from "./fontawesome-icons";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import AdminLayout from "./components/layouts/admin/AdminLayout.vue";
import CompanyLayout from "./components/layouts/company/CompanyLayout.vue";
import LayoutDefault from "@/components/layouts/LayoutDefault.vue";
import Notifications from '@kyvg/vue3-notification'
import BaseButton from "@/components/BaseButton.vue";
import BackButton from "@/components/BackButton.vue";
import VueMixin from './VueMixin';
import { createI18n } from 'vue-i18n';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'
import "./styles/vuetify-setting.scss";
import _ from "lodash";

const defaultLang = "ja";
const i18n = createI18n({
  messages: {
    ja: require("./locales/ja/index.json"),
  },
  locale: localStorage.getItem("locale") || defaultLang, // set locale
  fallbackLocale: defaultLang, // set fallback locale
})

const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents
  },
  directives,
    icons: {
      defaultSet: 'mdi',
    },
  theme: {
    defaultTheme: 'light'
  },
  locale: {
    locale: 'ja',
    fallback: 'en',
  },
})

const app = createApp(App);
app.config.isCustomElement = (tag) => tag.startsWith("ion-");
app.mixin(VueMixin);
app
  .use(vuetify)
  .component("VueDatePicker", VueDatePicker)
  .component("font-awesome-icon", FontAwesomeIcon)
	.component('layout-default', LayoutDefault)
	.component('admin-layout', AdminLayout)
	.component('company-layout', CompanyLayout)
  .component("base-button", BaseButton)
  .component("back-button", BackButton)
  .use(Notifications)
  .use(router)
  .use(store)
  .use(i18n)
  .use(_)
  .mount("#app");
