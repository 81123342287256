<template>
  <nav class="sidebar">
    <p role="button" @click="naviageTo(ADMIN_ROUTER_NAMES.home)">{{ sidebarTitle }}</p>
    <div class="sidebar__item" v-if="displayPerson">
      <img src="../../../assets/person-sidebar.png" alt="image" />
      <p>テーブル一覧</p>
    </div>
    <div class="sidebar__item" v-if="displayFile">
      <img src="../../../assets/file-sidebar.png" alt="image" />
      <p>注文受付</p>
    </div>
    <div class="sidebar__item" v-if="displayCheckbox">
      <img src="../../../assets/checkbox-sidebar.png" alt="image" />
      <p>予約情報管理</p>
    </div>
    <div class="sidebar__item" v-if="displayCard">
      <img src="../../../assets/card-sidebar.png" alt="image" />
      <p>決済情報出力</p>
    </div>
    <div
      class="sidebar__item"
      v-if="displayNote"
      @click="naviageTo(ADMIN_ROUTER_NAMES.home)"
    >
      <img src="../../../assets/note-sidebar.png" alt="image" />
      <p>トップ</p>
    </div>
    <div class="sidebar__item">
      <img src="../../../assets/box-sidebar.png" alt="image" />
      <p>商品</p>
    </div>
    <div class="sidebar__item" @click="naviageTo(ADMIN_ROUTER_NAMES.accountSetting)">
      <img src="../../../assets/setting-sidebar.png" alt="image" />
      <p>設定</p>
    </div>
    <div class="sidebar__item" @click="naviageTo(ADMIN_ROUTER_NAMES.masterSetting)">
      <img src="../../../assets/setting-sidebar.png" alt="image" />
      <p>マスタ設定</p>
    </div>
  </nav>
</template>

<script>
import { ADMIN_ROUTER_NAMES } from "@/router/adminGroup";

export default {
  name: "SideBar",
  setup() {
    return {
      ADMIN_ROUTER_NAMES,
    };
  },
  props: {
    displayPerson: Boolean,
    displayFile: Boolean,
    displayCheckbox: Boolean,
    displayCard: Boolean,
    displayNote: Boolean,
    displayBox: Boolean,
    sidebarTitle: String,
  },
  methods: {
    naviageTo(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/layouts/admin/sidebar.scss";
</style>
