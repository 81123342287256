import { PATH_URL } from "@/constants";
import ApiInstance from "./axiosBase";
import { toParams } from "@/utils/ObjectUtils";

export const AdminGetListStore = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/list` + params);
};

export const GetDetailStore = (id: any) => {
  return ApiInstance.get(`${PATH_URL.STORE}/${id}`);
};

export const UpdateDisplayStore = (body: any) => {
  return ApiInstance.put(`${PATH_URL.STORE}/update/display`, body);
};

export const GetListDrawer = () => {
  return ApiInstance.get(`${PATH_URL.STORE}/drawer/list`);
};

export const GetListPrint = () => {
  return ApiInstance.get(`${PATH_URL.STORE}/print/list`);
};

export const GetListCashbackMachine = () => {
  return ApiInstance.get(`${PATH_URL.STORE}/cashback-machine/list`);
};

export const DeleteStore = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/delete/${id}`);
};


export const DeleteDrawer = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/drawer/delete/${id}`);
};

export const DeletePrint = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/print/delete/${id}`);
};

export const DeleteCashbackMachine = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/cashback-machine/delete/${id}`);
};

export const DeletePosMachine = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/pos-machine/delete/${id}`);
};

export const DetailPosMachine = (id: number) => {
  return ApiInstance.get(`${PATH_URL.STORE}/pos-machine/detail/${id}`);
};

export const UpdateDisplay = (body: { id: number, isDisplay: number }) => {
  return ApiInstance.put(`${PATH_URL.STORE}/pos-machine/update/display/${body.id}`, { isDisplay: body.isDisplay });
};

export const AdminGetListPosMachine = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.STORE}/pos-machine/list` + params);
};

export const CreateStore = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/create`, body);
};

export const UpdateStore = (body: any) => {
  const { id, ...rest } = body;
  return ApiInstance.put(`${PATH_URL.STORE}/update/${id}`, rest);
};

export const UpdatePosMachine = (body: any) => {
  const { id, ...rest } = body;
  return ApiInstance.put(`${PATH_URL.STORE}/pos-machine/update/${id}`, rest);
};

export const UpdatePrint = (body: any) => {
  const { id, ...rest } = body;
  return ApiInstance.put(`${PATH_URL.STORE}/print/update/${id}`, rest);
};

export const UpdateCashbackMachine = (body: any) => {
  const { id, ...rest } = body;
  return ApiInstance.put(`${PATH_URL.STORE}/cashback-machine/update/${id}`, rest);
};

export const CreatePrint = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/print/create`, body);
};

export const CreateCashbackMachine = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/cashback-machine/create`, body);
};

export const CreatePosMachine = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/pos-machine/create`, body);
};

export const CreateDrawer = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/drawer/create`, body);
};

export const GetListCategory = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/category/list` + params);
};

export const DeleteCategory = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/category/${id}`);
};

export const DetailCategory = (id: number) => {
  return ApiInstance.get(`${PATH_URL.STORE}/category/${id}`);
};

export const UpdateDisplayCategory = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/category/update/display`, body);
};
export const UpdateChildDisplayCategory = (body: any) => {
  return ApiInstance.put(`${PATH_URL.STORE}/category/update/child/display`, body);
}

export const CreateCategory = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/category/create`, body);
};

export const UpdateCategory = (body: any) => {
  const { id, ...rest } = body;
  return ApiInstance.put(`${PATH_URL.STORE}/category/update/${id}`, rest.categoryLanguages);
}

export const DownloadCSVCategory = () => {
  ApiInstance.get(`${PATH_URL.STORE}/category/export-csv`, { responseType: 'blob' }).then((response: any) => {
    const href = window.URL.createObjectURL(response);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'カテゴリ登録.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};
