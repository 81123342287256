<template>
  <div id="app">
    <router-view></router-view>
    <notifications />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="scss">
@import "@/styles/_common";
body,
html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
#app {
  font-family: 'Noto Sans JP', sans-serif, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #ffffff;

  ::-webkit-input-placeholder {
    /* Edge */
    color: $colorPlaceHolder;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $colorPlaceHolder;
    opacity: 1;
  }

  ::placeholder {
    color: $colorPlaceHolder;
    opacity: 1;
  }
}
</style>
