export const ACCOUNT_TYPES = {
  CUSTOMER: "0",
  SYSTEM_ADMIN: "1",
  STORE: "2",
  COMPANY_MANAGER: "3",
  COMPANY_STAFF: "4",
};

export const GET_ACCOUNT_TYPES = (role: string) => Object.entries((ACCOUNT_TYPES)).find(([key, value]) => value == role)?.[0];

export const STATUSES = {
  DEACTIVATE: 0,
  ACTIVE: 1,
  BLOCK: 3,
  SOFT_DELETE: 4,
};

export const STAFF_ROLES = {
  ADMIN: '管理者',
  STAFF: 'スタッフ',
};

export const DATA_IMPORT_STAFF = {
  email: 'メールアドレス',
  name: '社員名',
  role: '管理権限',
  password: "パスワード"
};

export const PATH_URL = {
  USER: '/user',
  STORE: '/store',
  ADMIN: '/system',
  COMPANY: '/company',
  SETTING: '/setting'
};

export const TOKEN_STATUS = {
  EXPIRED: 1,
  USED: 2,
};

export const DATE_FORMAT = {
  MONTH_YEAR: 'MM/yyyy',
  YEAR: 'yyyy',
  MONTH: 'MM',
};


export const DISPLAY_FLAGS = {
  SHOW: 1,
  HIDE: 0,
}

export const TYPE_POS = {
  AUTO: 1,
  SELF: 2,
}

export const REGEX_ONLY_NUMBER = /[^0-9]/g;

export const DRAG_OPTION = {
  animation: 200,
  group: "description",
  disabled: false,
  ghostClass: "ghost",
};

export const LANGUAGES = {
  JA: {
    key: "ja",
    value: 1,
    index: 0
  },
  EN: {
    key: "en",
    value: 2,
    index: 1
  }
}

export const LEVELS_CATEGORY = {
  PARENT: 1,
  CHILD: 2,
  GRAND_CHILD: 3
};

export const REFUND_STATUSES = {
  ALLOW: 1,
  DISALLOW: 0
}

export const REFUND_PERIOD = [3, 5, 7, 9];

export const SALE_PROHIBITED = {
  YES: 1,
  NO: 0
}

export const TAX_CALC_METHOD = [
  {
    name: 'tax_calc_method.round',
    value: 'round'
  },
  {
    name: 'tax_calc_method.floor',
    value: 'floor'
  },
  {
    name: 'tax_calc_method.ceil',
    value: 'ceil'
  }
]

export const TYPE_REQUIRED_IF = {
  CHECK_VALUE: 'checkValue',
  BOOLEAN: 'boolean'
}

export const TYPE_CONDITION_SET_PRICE = {
  CURRENCY: 1,
  DISCOUNT_CURRENCY: 2,
  PERCENT_DISCOUNT: 3
}

export const HOST_URL_IMAGE = process.env.VUE_APP_API_URL + "/image/";
