import { SET_DETAIL_BUNDLE_PRODUCT, SET_LIST_BUNDLE_PRODUCT } from './../mutations.type';
import {
  CREATE_SET_PRODUCT,
  DELETE_BUNDLE_PRODUCT,
  DELETE_PRODUCT,
  DELETE_SET_PRODUCT,
  DETAIL_SET_PRODUCT,
  DOWNLOAD_CSV_BUNDLE_PRODUCT,
  LIST_BUNDLE_PRODUCT,
  LIST_PRODUCT,
  LIST_SET_PRODUCT,
  UPDATE_DISPLAY_BUNDLE_PRODUCT,
  UPDATE_DISPLAY_PRODUCT,
  UPDATE_DISPLAY_SET_PRODUCT,
  UPDATE_SET_PRODUCT,
  DETAIL_BUNDLE_PRODUCT,
  UPDATE_BUNDLE_PRODUCT,
  DETAIL_PRODUCT,
  UPDATE_PRODUCT,
} from "./../actions.type";
import {
  SET_CREATED_CATEGORY,
  SET_DETAIL_PERMISSION,
  SET_DETAIL_PRODUCT,
  SET_DETAIL_SET_PRODUCT,
  SET_ERROR,
  SET_LIST_PERMISSION,
  SET_LIST_PRODUCT,
  SET_LIST_SET_PRODUCT,
  SET_LIST_STAFF,
  SET_LIST_STORE_GROUP,
  SET_LOADING,
  SET_PLASTIC_BAGS,
  SET_UPDATED_DATA,
} from "../mutations.type";
import {
  COMPANY_CREATE_STAFF,
  COMPANY_DELETE_STAFF,
  COMPANY_DOWNLOAD_CSV,
  COMPANY_IMPORT_STAFF,
  COMPANY_LIST_STAFF,
  COMPANY_UPDATE_DISPLAY_STAFF,
  COMPANY_UPDATE_STAFF,
  COMPANY_UPDATE_STATUS_STAFF,
  CREATE_PRODUCT,
  DELETE_PERMISSION,
  DELETE_PLASTIC_BAGS,
  DELETE_STORE_GROUP,
  DETAIL_PERMISSION,
  DOWNLOAD_CSV_SET_PRODUCT,
  GET_PLASTIC_BAGS,
  LIST_PERMISSION,
  LIST_STORE_GROUP,
  UPDATE_DISPLAY_PERMISSION,
  UPDATE_DISPLAY_STORE_GROUP,
  UPDATE_PERMISSION,
  UPDATE_PLASTIC_BAGS_DISPLAY
} from "../actions.type";
import {
  CompanyImportStaff,
  CreateProduct,
  CreateSetProduct,
  CreateStaff,
  DeleteBundleProduct,
  DeletePermission,
  DeletePlasticBags,
  DeleteProduct,
  DeleteSetProduct,
  DeleteStaff,
  DeleteStoreGroup,
  DetailSetProduct,
  DownloadCSV,
  DownloadCSVBundleProduct,
  DownloadCSVSetProduct,
  EditPermission,
  GetListBundleProduct,
  GetListPermission,
  GetListProduct,
  GetListSetProduct,
  GetListStaff,
  GetListStoreGroup,
  GetPlasticBags,
  UpdateDisplayBundleProduct,
  UpdateDisplayPermission,
  UpdateDisplayPlasticBags,
  UpdateDisplayProduct,
  UpdateDisplaySetProduct,
  UpdateDisplayStoreGroup,
  UpdatePermission,
  UpdateSetProduct,
  UpdateStaff,
  UpdateStaffStatus,
  DetailBundleProduct,
  UpdateBundleProduct,
  DetailProduct,
  UpdateProduct,
} from "@/services/api/company.service";
import { IUpdateDisplay } from "@/services/api/models/IDisplay";
import { ICreateOrUpdateStaff } from "@/services/api/models/ICreateStaff";
import { formatNumber } from "@/utils";
import { getCategoryItem } from "@/utils";
import { LEVELS_CATEGORY, TYPE_CONDITION_SET_PRICE } from "@/constants";

const state = {
  loading: false,
  errors: null,
  updatedData: null,
  storeGroup: [],
  permissions: [],
  staffs: [],
  permission: null,
  plasticBags: [],
  products: [],
  product: {},
  setProducts: [],
  setProduct: {},
  bundleProducts: [],
  bundleProduct: {}
};

const getters = {
  getPermission() {
    return state.permission;
  },
  updatedData() {
    return state.updatedData;
  },
  getSetProducts(state: any) {
    return state.setProducts?.map((item: any) => {
      item.ids = item.ids ? item.ids.split(',') : [];
      return item;
    })
  },
  getSetProduct(state: any) {
    const data = state.setProduct;
    const productIds = data.setProductAndProductPivots?.map((item: any) => {
      return item?.product?.id;
    });
    const storeIds = data.setProductAndStorePivots?.map((item: any) => {
      return item?.store?.id;
    });
    const discountTargetItems = data.discountTargetItems ? formatNumber(data.discountTargetItems) : null;
    const price = data.price ? formatNumber(data.price) : null;
    return {
      ...data,
      productIds: productIds,
      storeIds: storeIds,
      discountTargetItems: discountTargetItems,
      price: price
    }
  },
  getBundleProduct(state: any) {
    const data = state.bundleProduct;
    const productIds = data.bundleProductAndProductPivots?.map((item: any) => {
      return item?.product?.id;
    });
    const storeIds = data.bundleProductAndStorePivots?.map((item: any) => {
      return item?.store?.id;
    });
    const discountTargetItems = data.discountTargetItems ? formatNumber(data.discountTargetItems) : null;
    const conditionSetPrice = JSON.parse(data.conditionSetPrice);
    const typeConditionSetPrice = conditionSetPrice?.type;
    const pointConditionSetPrice = conditionSetPrice?.point;
    const valueConditionSetPrice = conditionSetPrice?.value;

    return {
      ...data,
      productIds: productIds,
      storeIds: storeIds,
      discountTargetItems: discountTargetItems,
      conditionSetPrice: typeConditionSetPrice,
      pointCurrency: typeConditionSetPrice === TYPE_CONDITION_SET_PRICE.CURRENCY ? pointConditionSetPrice : null,
      pointDiscountCurrency: typeConditionSetPrice === TYPE_CONDITION_SET_PRICE.DISCOUNT_CURRENCY ? pointConditionSetPrice : null,
      pointPercentDiscount: typeConditionSetPrice === TYPE_CONDITION_SET_PRICE.PERCENT_DISCOUNT ? pointConditionSetPrice : null,
      currency: typeConditionSetPrice === TYPE_CONDITION_SET_PRICE.CURRENCY ? valueConditionSetPrice : null,
      discountCurrency: typeConditionSetPrice === TYPE_CONDITION_SET_PRICE.DISCOUNT_CURRENCY ? valueConditionSetPrice : null,
      percentDiscount: typeConditionSetPrice === TYPE_CONDITION_SET_PRICE.PERCENT_DISCOUNT ? valueConditionSetPrice : null,
    }
  },
  getStoreGroup(state: any) {
    return state.storeGroup;
  },
  getDetailProduct(state: any) {
    const storeIds = state.product.productAndStorePivots.map((item: { store: { id: any; }; }) => item.store.id);
    return {
    ...state.product,
      price: formatNumber(state.product.price),
      maxQuantity: formatNumber(state.product.maxQuantity),
      productLangDTOS: state.product?.productLang,
      productVariationDTOS: state.product?.productVariations.map((item: any) => {
        return {
          ...item,
          price: formatNumber(item.price),
        }
      }),
      rootCategory: getCategoryItem(state.product?.categoryAndProductPivot, LEVELS_CATEGORY.PARENT),
      childCategory: getCategoryItem(state.product?.categoryAndProductPivot, LEVELS_CATEGORY.CHILD),
      grandChildCategory: getCategoryItem(state.product?.categoryAndProductPivot, LEVELS_CATEGORY.GRAND_CHILD),
      productSalesPeriod: state.product?.productSalesPeriod ? state.product.productSalesPeriod.split('~') : null,
      storeIds: storeIds
    }
  }
};

const actions = {
  // STORE_GROUP
  async [LIST_STORE_GROUP](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await GetListStoreGroup(query);
      context.commit(SET_LIST_STORE_GROUP, response.data);
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      return [];
    }
  },
  async [UPDATE_DISPLAY_STORE_GROUP](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateDisplayStoreGroup(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_STORE_GROUP](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeleteStoreGroup(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  // PERMISSION
  async [LIST_PERMISSION](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await GetListPermission(query);
      context.commit(SET_LIST_PERMISSION, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_DISPLAY_PERMISSION](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateDisplayPermission(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_PERMISSION](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeletePermission(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DETAIL_PERMISSION](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      const res = await EditPermission(id);
      context.commit(SET_DETAIL_PERMISSION, res.data);
      context.commit(SET_ERROR, null);
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
  },
  async [UPDATE_PERMISSION](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      const { id, ...data } = body;
      context.commit(SET_LOADING, true);
      await UpdatePermission(data, id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
  },
  // STAFF
  async [COMPANY_LIST_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    query: { keyword: string; role: string }
  ) {
    try {
      const response = await GetListStaff(query);
      context.commit(SET_LIST_STAFF, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [COMPANY_CREATE_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    body: ICreateOrUpdateStaff
  ) {
    try {
      context.commit(SET_LOADING, true);
      await CreateStaff(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [COMPANY_UPDATE_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    data: { body: ICreateOrUpdateStaff; id: number }
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateStaff(data.body, data.id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [COMPANY_UPDATE_DISPLAY_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    body: IUpdateDisplay
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateDisplayPermission(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [COMPANY_UPDATE_STATUS_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    body: { ids: number[]; statusId: number }
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateStaffStatus(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [COMPANY_DELETE_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeleteStaff(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [COMPANY_DOWNLOAD_CSV](context: {
    commit: (arg0: string, arg1: any) => void;
  }) {
    try {
      const response = DownloadCSV();
      context.commit(SET_ERROR, null);
      return response;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [COMPANY_IMPORT_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    body: ICreateOrUpdateStaff[]
  ) {
    try {
      context.commit(SET_LOADING, true);
      await CompanyImportStaff(body);
      context.commit(SET_LOADING, false);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [GET_PLASTIC_BAGS](context: {
    commit: (arg0: string, arg1: any) => void;
  }) {
    try {
      const res = await GetPlasticBags();
      context.commit(SET_PLASTIC_BAGS, res.data);
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
  },

  async [DELETE_PLASTIC_BAGS](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeletePlasticBags(id);
      context.commit(SET_ERROR, null);
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
  },
  async [UPDATE_PLASTIC_BAGS_DISPLAY](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      await UpdateDisplayPlasticBags(body);
      context.commit(SET_ERROR, null);
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
  },
  async [CREATE_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ){
    try {
      await CreateProduct(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (e) {
      context.commit(SET_ERROR, e);
    }
  },
  async [LIST_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      const response = await GetListProduct(body);
      context.commit(SET_LIST_PRODUCT, response.data);
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeleteProduct(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_DISPLAY_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateDisplayProduct(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [DETAIL_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      const response = await DetailProduct(id);
      context.commit(SET_DETAIL_PRODUCT, response.data);
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateProduct(body);
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [LIST_SET_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      const response = await GetListSetProduct(body);
      context.commit(SET_LIST_SET_PRODUCT, response.data);
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_SET_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeleteSetProduct(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_DISPLAY_SET_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateDisplaySetProduct(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [CREATE_SET_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      const res = await CreateSetProduct(body);
      context.commit(SET_CREATED_CATEGORY, res.data);
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [DETAIL_SET_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      const response = await DetailSetProduct(id);
      context.commit(SET_DETAIL_SET_PRODUCT, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_SET_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateSetProduct(body);
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DOWNLOAD_CSV_SET_PRODUCT](context: {
    commit: (arg0: string, arg1: any) => void;
  }) {
    try {
      const response = DownloadCSVSetProduct();
      context.commit(SET_ERROR, null);
      return response;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [LIST_BUNDLE_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      const response = await GetListBundleProduct(body);
      context.commit(SET_LIST_BUNDLE_PRODUCT, response.data);
      context.commit(SET_ERROR, null);
      return response.data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DELETE_BUNDLE_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      await DeleteBundleProduct(id);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_DISPLAY_BUNDLE_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateDisplayBundleProduct(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [DOWNLOAD_CSV_BUNDLE_PRODUCT](context: {
    commit: (arg0: string, arg1: any) => void;
  }) {
    try {
      const response = DownloadCSVBundleProduct();
      context.commit(SET_ERROR, null);
      return response;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [DETAIL_BUNDLE_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    id: number
  ) {
    try {
      const response = await DetailBundleProduct(id);
      context.commit(SET_DETAIL_BUNDLE_PRODUCT, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_BUNDLE_PRODUCT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await UpdateBundleProduct(body);
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
};

const mutations = {
  [SET_ERROR](state: any, error: any) {
    state.errors = error;
  },
  [SET_LOADING](state: any, loading: boolean) {
    state.loading = loading;
  },
  [SET_UPDATED_DATA](state: any, data: any) {
    state.updatedData = data;
  },
  [SET_LIST_STORE_GROUP](state: any, responseData: any) {
    state.storeGroup = responseData;
  },
  [SET_LIST_PERMISSION](state: any, responseData: any) {
    state.permissions = responseData;
  },
  [SET_LIST_STAFF](state: any, responseData: any) {
    state.staffs = responseData;
  },
  [SET_DETAIL_PERMISSION](state: any, responseData: any) {
    state.permission = responseData;
  },
  [SET_PLASTIC_BAGS](state: any, responseData: any) {
    state.plasticBags = responseData;
  },
  [SET_LIST_PRODUCT](state: any, responseData: any) {
    state.products = responseData;
  },
  [SET_DETAIL_PRODUCT](state: any, responseData: any) {
    state.product = responseData;
  },
  [SET_LIST_SET_PRODUCT](state: any, responseData: any) {
    state.setProducts = responseData;
  },
  [SET_DETAIL_SET_PRODUCT](state: any, responseData: any) {
    state.setProduct = responseData;
  },
  [SET_LIST_BUNDLE_PRODUCT](state: any, responseData: any) {
    state.bundleProducts = responseData;
  },
  [SET_DETAIL_BUNDLE_PRODUCT](state: any, responseData: any) {
    state.bundleProduct = responseData;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
