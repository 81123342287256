import { PATH_URL } from "@/constants";
import ApiInstance from "./axiosBase";

export const AdminCreateSetting = (body: any) => {
  return ApiInstance.post(`${PATH_URL.SETTING}/create`, body);
};

export const AdminUpdateSetting = (body: any) => {
  return ApiInstance.put(`${PATH_URL.SETTING}/update`, body);
}

export const AdminGetDetailSetting = () => {
  return ApiInstance.get(`${PATH_URL.SETTING}/detail`);
}
