<template>
  <div class="admin-container">
    <SideBar
      :sidebar-title="sidebarTitle"
      :display-person="displayPerson"
      :display-file="displayFile"
      :display-checkbox="displayCheckbox"
      :display-card="displayCard"
      :display-note="displayNote"
      :display-box="displayBox"
    />
    <div class="admin-container__content">
      <HeaderAdmin
        :page-title="pageTitle"
        :display-logo="displayLogo"
        :display-printer="displayPrinter"
        :display-reload="displayReload"
      />
      <slot name="content"></slot>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="js">
import SideBar from './Sidebar.vue';
import HeaderAdmin from './Header.vue';

export default {
  components: {
    SideBar,
    HeaderAdmin
  },
  props: {
    pageTitle: String,
    sidebarTitle: {
      type: String,
      default: "とどろく",
    },
    displayLogo: {
      type: Boolean,
      default: true,
    },
    displayPerson: {
      type: Boolean,
      default: true,
    },
    displayFile: {
      type: Boolean,
      default: true,
    },
    displayCheckbox: {
      type: Boolean,
      default: true,
    },
    displayCard: {
      type: Boolean,
      default: true,
    },
    displayNote: {
      type: Boolean,
      default: true,
    },
    displayBox: {
      type: Boolean,
      default: true,
    },
    displayPrinter: {
      type: Boolean,
      default: true,
    },
    displayReload: {
      type: Boolean,
      default: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.admin-container {
  display: flex;
  height: 100vh;
  margin: auto;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
</style>
