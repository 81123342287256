import PassThroughVue from "@/components/layouts/PassThrough.vue";
import RoutesNames from "./routesNames";
import { ACCOUNT_TYPES } from "@/constants";
const COMPANY_ROUTER_NAMES = {
  home: RoutesNames.company[1],
  login: RoutesNames.company[0],
  listStore: "company_listStore",
  createStore: "company_createStore",
  detailStore: "company_detailStore",
  storeGroup: "storeGroup",
  listPermission: "listPermission",
  detailPermission: "detailPermission",
  staffRegistrationDetail: "staffRegistrationDetail",
  changeRegisteredInformation: "changeRegisteredInformation",
  accountSetting: "company_accountSetting",
  changePassword: "company_changePassword",
  changeEmail: "company_changeEmail",
  registerBag: "company_registerBag",
  detailCategory: "company_detailCategory",
  listCategory: "company_listCategory",
  createProduct: "company_createProduct",
  detailProduct: "company_detailProduct",
  listProduct: "company_listProduct",
  listSetProduct: "listSetProduct",
  detailSetProduct: "detailSetProduct",
  listBundleProduct: "listBundleProduct",
  detailBundleProduct: "detailBundleProduct",
};

const companyRouters =
{
  path: "/company",
  name: "company_pages",
  component: PassThroughVue,
  children: [
    {
      path: "",
      name: COMPANY_ROUTER_NAMES.home,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER, ACCOUNT_TYPES.COMPANY_STAFF],
      },
      component: () =>
        import(/* webpackChunkName: "s911" */ "@/views/admin/ViewS800.vue"),
    },
    {
      path: "store/list",
      name: COMPANY_ROUTER_NAMES.listStore,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER, ACCOUNT_TYPES.COMPANY_STAFF],
      },
      component: () =>
        import(/* webpackChunkName: "s801" */ "@/views/admin/ViewS801.vue"),
    },
    {
      path: "store/create",
      name: COMPANY_ROUTER_NAMES.createStore,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER, ACCOUNT_TYPES.COMPANY_STAFF],
      },
      component: () =>
        import(/* webpackChunkName: "s802" */ "@/views/admin/ViewS802.vue"),
    },
    {
      path: "store/:id",
      name: COMPANY_ROUTER_NAMES.detailStore,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER, ACCOUNT_TYPES.COMPANY_STAFF],
      },
      component: () =>
        import(/* webpackChunkName: "s802" */ "@/views/admin/ViewS802-1.vue"),
    },
    {
      path: "store-group",
      name: COMPANY_ROUTER_NAMES.storeGroup,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER, ACCOUNT_TYPES.COMPANY_STAFF],
      },
      component: () =>
        import(/* webpackChunkName: "s803" */ "@/views/admin/ViewS803.vue"),
    },
    {
      path: "permission/list",
      name: COMPANY_ROUTER_NAMES.listPermission,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER, ACCOUNT_TYPES.COMPANY_STAFF],
      },
      component: () =>
        import(/* webpackChunkName: "s811" */ "@/views/admin/ViewS811.vue"),
    },
    {
      path: "permission/:id",
      name: COMPANY_ROUTER_NAMES.detailPermission,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER, ACCOUNT_TYPES.COMPANY_STAFF],
      },
      component: () =>
        import(/* webpackChunkName: "s812" */ "@/views/admin/ViewS812.vue"),
    },
    {
      path: "staff",
      name: COMPANY_ROUTER_NAMES.staffRegistrationDetail,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s813" */ "@/views/admin/ViewS813.vue"),
    },
    {
      path: "change-registered-information",
      name: COMPANY_ROUTER_NAMES.changeRegisteredInformation,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER, ACCOUNT_TYPES.COMPANY_STAFF],
      },
      component: () =>
        import(/* webpackChunkName: "s821" */ "@/views/admin/ViewS821.vue"),
    },
    {
      path: "account-setting",
      name: COMPANY_ROUTER_NAMES.accountSetting,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER, ACCOUNT_TYPES.COMPANY_STAFF, ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s822" */ "@/views/admin/ViewS822.vue"),
    },
    {
      path: "change-password",
      name: COMPANY_ROUTER_NAMES.changePassword,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER, ACCOUNT_TYPES.COMPANY_STAFF],
      },
      component: () =>
        import(/* webpackChunkName: "s823" */ "@/views/admin/ViewS823.vue"),
    },
    {
      path: "change-email",
      name: COMPANY_ROUTER_NAMES.changeEmail,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER, ACCOUNT_TYPES.COMPANY_STAFF],
      },
      component: () =>
        import(/* webpackChunkName: "s824" */ "@/views/admin/ViewS824.vue"),
    },
    {
      path: "register-bag",
      name: COMPANY_ROUTER_NAMES.registerBag,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s717" */ "@/views/admin/ViewS717.vue"),
    },
    {
      path: "category/list",
      name: COMPANY_ROUTER_NAMES.listCategory,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s701" */ "@/views/admin/ViewS701.vue"),
    },
    {
      path: "category/:id",
      name: COMPANY_ROUTER_NAMES.detailCategory,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s703" */ "@/views/admin/ViewS703.vue"),
    },
    {
      path: "product/list",
      name: COMPANY_ROUTER_NAMES.listProduct,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s711" */ "@/views/admin/ViewS711.vue"),
    },
    {
      path: "product/create",
      name: COMPANY_ROUTER_NAMES.createProduct, 
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s712-1" */ "@/views/admin/ViewS712-1.vue"),
    },
    {
      path: "product/:id",
      name: COMPANY_ROUTER_NAMES.detailProduct, 
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s712-1" */ "@/views/admin/ViewS712-2.vue"),
    },
    {
      path: "set-product/list",
      name: COMPANY_ROUTER_NAMES.listSetProduct,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s721" */ "@/views/admin/ViewS721.vue"),
    },
    {
      path: "set-product/:id",
      name: COMPANY_ROUTER_NAMES.detailSetProduct,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s722" */ "@/views/admin/ViewS722.vue"),
    },
    {
      path: "bundle-product/list",
      name: COMPANY_ROUTER_NAMES.listBundleProduct,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s725" */ "@/views/admin/ViewS725.vue"),
    },
    {
      path: "bundle-product/:id",
      name: COMPANY_ROUTER_NAMES.detailBundleProduct,
      meta: {
        requiresAuth: true,
        redirect: COMPANY_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s726" */ "@/views/admin/ViewS726.vue"),
    },
  ],
};

export { companyRouters, COMPANY_ROUTER_NAMES };
