export interface IRoutesNames {
  customer: string[];
  store: string[];
  company: string[];
  admin: string[];
}

const routesNames: Readonly<IRoutesNames> = {
  customer: ["customerLogin", "customer"],
  store: ["adminLogin", "store"],
  company: ["adminLogin", "company"],
  admin: ["adminLogin", "admin"],
};

export default routesNames;
