import { CREATE_SETTING, GET_MASTER_SETTING, UPDATE_MASTER_SETTING } from "@/store/actions.type";
import { SET_ERROR, SET_LOADING, SET_MASTER_SETTING } from "@/store/mutations.type";
import { AdminCreateSetting, AdminGetDetailSetting, AdminUpdateSetting } from "@/services/api/setting.service";

const state = {
  loading: false,
  errors: null,
  masterSetting: null,
};
const getters = {
  getMasterSetting(sate: any) {
    const dataMasterSetting: any = state.masterSetting;
    if (dataMasterSetting) {
      dataMasterSetting.designatedEmail = dataMasterSetting?.designatedEmail ? JSON.parse(dataMasterSetting?.designatedEmail) : [''];
      dataMasterSetting.taxSetting = dataMasterSetting?.taxSetting ? JSON.parse(dataMasterSetting?.taxSetting) : [{ name: null, rate: null }];
      dataMasterSetting.taxCalcMethod = dataMasterSetting?.taxCalcMethod ? JSON.parse(dataMasterSetting?.taxCalcMethod) : null
    }
    return dataMasterSetting
  }
};

const actions = {
  async [CREATE_SETTING](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await AdminCreateSetting(body);
      context.commit(SET_LOADING, false);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [GET_MASTER_SETTING](
    context: { commit: (arg0: string, arg1: any) => void }
  ){
    try {
      context.commit(SET_LOADING, true);
      const res = await AdminGetDetailSetting();
      const data = res.data ?? {};
      context.commit(SET_MASTER_SETTING, data);
      context.commit(SET_LOADING, false);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [UPDATE_MASTER_SETTING](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await AdminUpdateSetting(body);
      context.commit(SET_LOADING, false);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  }
}

const mutations = {
  [SET_ERROR](state: any, error: any) {
    state.errors = error;
  },
  [SET_LOADING](state: any, loading: boolean) {
    state.loading = loading;
  },
  [SET_MASTER_SETTING](state: any, data: any) {
    state.masterSetting = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
