import { SET_LIST_COMPANY, SET_LOADING, SET_DETAIL_COMPANY } from './../mutations.type';
import { LIST_STORE_ADMIN, CREATE_COMPANY, DETAIL_COMPANY, UPDATE_COMPANY } from "../actions.type";
import { SET_ERROR, SET_UPDATED_DATA } from "../mutations.type";
import { AdminGetListStore, AdminCreateCompany, AdminDetailCompany, AdminUpdateCompany } from '@/services/api/admin.service';
import { ADMIN_ROUTER_NAMES } from "@/router/adminGroup";
import router from "@/router";

const state = {
  loading: false,
  errors: null,
  updatedData: null,
  company: {},
  companies: null
};

const getters = {};

const actions = {
  async [CREATE_COMPANY](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await AdminCreateCompany(data.data);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
      router.push(data.redirectPath || { name: ADMIN_ROUTER_NAMES.home });
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [DETAIL_COMPANY](
    context: { commit: (arg0: string, arg1: any) => void },
    companyId: any
  ) {
    try {
      const { data } = await AdminDetailCompany(companyId);
      context.commit(SET_DETAIL_COMPANY, data);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [UPDATE_COMPANY](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await AdminUpdateCompany(data.data);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
      router.push(data.redirectPath || {name: ADMIN_ROUTER_NAMES.home});
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },

  async [LIST_STORE_ADMIN](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      const res = await AdminGetListStore(body);
      context.commit(SET_LIST_COMPANY, res.data);
      context.commit(SET_LOADING, false);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  }
};

const mutations = {
  [SET_ERROR](state: any, error: any) {
    state.errors = error;
  },
  [SET_LOADING](state: any, loading: boolean) {
    state.loading = loading;
  },
  [SET_UPDATED_DATA](state: any, data: any) {
    state.updatedData = data;
  },
  [SET_DETAIL_COMPANY](state: any, responseData: any) {
    state.company = responseData;
  },
  [SET_LIST_COMPANY](state: any, data: any) {
    state.companies = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
