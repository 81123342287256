import {
  ILoginRequest,
  IAdminLoginRequest
} from "./models/IAuth";
import ApiInstance from "./axiosBase";

export const PATH_AUTH_CURRENT_USER = '/auth/current-user';
const PATH_AUTH = '/auth';


export  const UserGetCurrent = () => {
  return ApiInstance.get(`${PATH_AUTH_CURRENT_USER}`);
};

export const UserLogin = (body: Partial<ILoginRequest>) => {
  return ApiInstance.post(`${PATH_AUTH}/login`, body);
};

export const AdminLogin = (body: Partial<IAdminLoginRequest>) => {
  return ApiInstance.post(`${PATH_AUTH}/admin/login`, body);
};

export const UserChangeEmail = (body: any) => {
  return ApiInstance.post(`${PATH_AUTH}/change-email`, body);
};

export const UserChangePassword = (body: any) => {
  return ApiInstance.post(`${PATH_AUTH}/change-password`, body);
};

export const UserConfirmChangeEmail = (token: string) => {
  return ApiInstance.post(`${PATH_AUTH}/change-email/confirm`, {
    token
  });
};

export const UserCheckValidToken = (token: string) => {
  return ApiInstance.get(`${PATH_AUTH}/valid-token?token=${token}`);
};

export const UserSendResetPasswordRequest = (body: any) => {
  return ApiInstance.post(`${PATH_AUTH}/reset-password`, body);
};

export const UserConfirmResetPasswordRequest = (data: any) => {
  return ApiInstance.post(`${PATH_AUTH}/reset-password/confirm`, data);
};
