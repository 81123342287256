<template>
  <v-btn class="btn__previous" @click="handleGoBack">もどる</v-btn>
</template>
<script>
export default {
  methods: {
    handleGoBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn__blue {
  min-width: 106px;
}
</style>
