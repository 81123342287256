<template>
  <div class="screen__header">
    <div class="screen__header--left"  @click="naviageToHomepage">
      <img src="../../assets/logo.png" alt="image" />
      <span>{{ title }}</span>
    </div>
    <div class="screen__header--right">
      <div v-if="displayStep" class="step--red">変更有 2/15</div>
      <button
        v-if="displayButton"
        type="button"
        class="btn__language"
        @click="naviageToC015"
      >
        Language
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    displayButton: Boolean,
    displayStep: Boolean,
  },
  methods: {
    naviageToC015() {
      this.$router.push({ path: "/language-switching" });
    },
    naviageToHomepage() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/layouts/client/header.scss";
</style>
