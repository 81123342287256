<template>
  <header class="screen__header">
    <div class="screen__header--left">
      <img src="../../../assets/logo.png" alt="image" v-if="displayLogo" />
      <span>{{ pageTitle }}</span>
    </div>
    <div class="screen__header--right">
      <div class="dropdown">
        <div class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="../../../assets/profile.png" alt="image"/>
          <img src="../../../assets/printer.png" alt="image" v-if="displayPrinter" />
          <img src="../../../assets/reload.png" alt="image" v-if="displayReload" />
        </div>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" href="javascript:void(0);"  @click="logout">{{ $t('buttons.logout') }}</a></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { LOGOUT } from "@/store/actions.type";
import routesNames from '@/router/routesNames';

export default {
  name: "HeaderAdmin",
  props: {
    pageTitle: String,
    displayLogo: Boolean,
    displayPrinter: Boolean,
    displayReload: Boolean,
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT, routesNames.admin[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/layouts/admin/header.scss";
</style>
