import PassThroughVue from "@/components/layouts/PassThrough.vue";
import RoutesNames from "./routesNames";
import { ACCOUNT_TYPES } from "@/constants";

const STORE_ROUTER_NAMES = {
  home: RoutesNames.store[1],
  login: RoutesNames.store[0],
  detailCategory: "detailCategory",
  categoryTranslation: "categoryTranslation",
  createProduct: "createProduct",
  productDetailRegistrationTwo: "productDetailRegistrationTwo",
  modalTargetProduct: "modalTargetProduct",
  productRegistrationMultilingual: "productRegistrationMultilingual",
  modalProductVariationRegistration: "modalProductVariationRegistration",
  modalProductVariationRegistrationTwo: "modalProductVariationRegistrationTwo",
  registerBag: "registerBag",
  modalTargetProductSelection: "modalTargetProductSelection",
  modalSetProductTarget: "modalSetProductTarget",
  productRegistrationBundle: "productRegistrationBundle",
  bundleProduct: "bundleProduct",
  modalEligibleProductSelection: "modalEligibleProductSelection",
  modalBundleProductTarget: "modalBundleProductTarget",
  saleSetting: "saleSetting",
  saleDetailsSetting: "saleDetailsSettings",
  modalSaleTargetProductSelectionCategory: "modalSaleTargetProductSelectionCategory",
  modalSaleTargetProductSelectionIndividual: "modalSaleTargetProductSelectionIndividual",
  modalSaleTargetStoreSettings: "modalSaleTargetStoreSettings",
  couponSetting: "couponSetting",
  addCoupon: "addCoupon",
  modalCouponTargetProductSelectionCategory: "modalCouponTargetProductSelectionCategory",
  modalCouponEligibleProductSelectionProduct: "modalCouponEligibleProductSelectionProduct",
  modalCouponEligibleStoreSettings: "modalCouponEligibleStoreSettings",
  cashRegister: "cashRegister",
  cashRegisterDetail: "cashRegisterDetail",
  cashDrawerRegistration: "cashDrawerRegistration",
  automaticChangeSetting: "automaticChangeSetting",
  rfidReaderRegistration: "rfidReaderRegistration",
  receiptPrinterRegistration: "receiptPrinterRegistration",
  paymentCategorySetting: "paymentCategorySetting",
  paymentMethodSetting: "paymentMethodSetting",
  depositCategoryRegistration: "depositCategoryRegistration",
  withdrawalClassificationRegistration: "withdrawalClassificationRegistration",
  inventoryManagement: "inventoryManagement",
  receiptSetting: "receiptSetting",
  masterSetting: "masterSetting",
  salesAnalysis: "salesAnalysis",
  salesAnalysisByProduct: "salesAnalysisByProduct",
  salesByStore: "salesByStore",
  transactionHistoryList: "transactionHistoryList",
  transactionHistoryDetail: "transactionHistoryDetail",
  transactionHistoryEdit: "transactionHistoryEdit",
  depositWithdrawalHistory: "depositWithdrawalHistory",
  cashRegisterInspectionHistory: "cashRegisterInspectionHistory",
  cashRegisterInspectionHistoryDetail: "cashRegisterInspectionPaymentHistoryDetail",
};

const storeRouters =
{
  path: "/store",
  name: "store_pages",
  component: PassThroughVue,
  children: [
    {
      path: "",
      name: STORE_ROUTER_NAMES.home,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s700" */ "@/views/admin/ViewS700.vue"),
    },
    {
      path: "category-translation",
      name: STORE_ROUTER_NAMES.categoryTranslation,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s702" */ "@/views/admin/ViewS702.vue"),
    },
    {
      path: "modalTargetProduct",
      name: STORE_ROUTER_NAMES.modalTargetProduct,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s713" */ "@/views/admin/ViewS713.vue"),
    },
    {
      path: "product-registration/multilingual",
      name: STORE_ROUTER_NAMES.productRegistrationMultilingual,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s714" */ "@/views/admin/ViewS714.vue"),
    },
    {
      path: "modalProductVariationRegistration",
      name: STORE_ROUTER_NAMES.modalProductVariationRegistration,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s715" */ "@/views/admin/ViewS715.vue"),
    },
    {
      path: "modalProductVariationRegistrationTwo",
      name: STORE_ROUTER_NAMES.modalProductVariationRegistrationTwo,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s716" */ "@/views/admin/ViewS716.vue"),
    },
    {
      path: "modalTargetProductSelection",
      name: STORE_ROUTER_NAMES.modalTargetProductSelection,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s723" */ "@/views/admin/ViewS723.vue"),
    },
    {
      path: "modalSetProductTarget",
      name: STORE_ROUTER_NAMES.modalSetProductTarget,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s724" */ "@/views/admin/ViewS724.vue"),
    },
    {
      path: "products-registration/bundle",
      name: STORE_ROUTER_NAMES.productRegistrationBundle,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s725" */ "@/views/admin/ViewS725.vue"),
    },
    {
      path: "modalEligibleProductSelection",
      name: STORE_ROUTER_NAMES.modalEligibleProductSelection,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s727" */ "@/views/admin/ViewS727.vue"),
    },
    {
      path: "modalBundleProductTarget",
      name: STORE_ROUTER_NAMES.modalBundleProductTarget,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s728" */ "@/views/admin/ViewS728.vue"),
    },
    {
      path: "sale-setting",
      name: STORE_ROUTER_NAMES.saleSetting,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s731" */ "@/views/admin/ViewS731.vue"),
    },
    {
      path: "sale-setting/detail",
      name: STORE_ROUTER_NAMES.saleDetailsSetting,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s732" */ "@/views/admin/ViewS732.vue"),
    },
    {
      path: "modalSaleTargetProductSelectionCategory",
      name: STORE_ROUTER_NAMES.modalSaleTargetProductSelectionCategory,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s733" */ "@/views/admin/ViewS733.vue"),
    },
    {
      path: "modalSaleTargetProductSelectionIndividual",
      name: STORE_ROUTER_NAMES.modalSaleTargetProductSelectionIndividual,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s734" */ "@/views/admin/ViewS734.vue"),
    },
    {
      path: "modalSaleTargetStoreSettings",
      name: STORE_ROUTER_NAMES.modalSaleTargetStoreSettings,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s735" */ "@/views/admin/ViewS735.vue"),
    },
    {
      path: "coupon-setting",
      name: STORE_ROUTER_NAMES.couponSetting,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s741" */ "@/views/admin/ViewS741.vue"),
    },
    {
      path: "add-coupon",
      name: STORE_ROUTER_NAMES.addCoupon,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE, ACCOUNT_TYPES.COMPANY_MANAGER],
      },
      component: () =>
        import(/* webpackChunkName: "s742" */ "@/views/admin/ViewS742.vue"),
    },
    {
      path: "modalCouponTargetProductSelectionCategory",
      name: STORE_ROUTER_NAMES.modalCouponTargetProductSelectionCategory,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s743" */ "@/views/admin/ViewS743.vue"),
    },
    {
      path: "modalCouponEligibleProductSelectionProduct",
      name: STORE_ROUTER_NAMES.modalCouponEligibleProductSelectionProduct,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s744" */ "@/views/admin/ViewS744.vue"),
    },
    {
      path: "modalCouponEligibleStoreSettings",
      name: STORE_ROUTER_NAMES.modalCouponEligibleStoreSettings,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s745" */ "@/views/admin/ViewS745.vue"),
    },
    {
      path: "cash-register",
      name: STORE_ROUTER_NAMES.cashRegister,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s751" */ "@/views/admin/ViewS751.vue"),
    },
    {
      path: "cash-register/detail",
      name: STORE_ROUTER_NAMES.cashRegisterDetail,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s752" */ "@/views/admin/ViewS752.vue"),
    },
    {
      path: "cash-drawer-registration",
      name: STORE_ROUTER_NAMES.cashDrawerRegistration,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s753" */ "@/views/admin/ViewS753.vue"),
    },
    {
      path: "automatic-change-setting",
      name: STORE_ROUTER_NAMES.automaticChangeSetting,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s754" */ "@/views/admin/ViewS754.vue"),
    },
    {
      path: "RFID-reader-registration",
      name: STORE_ROUTER_NAMES.rfidReaderRegistration,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s755" */ "@/views/admin/ViewS755.vue"),
    },
    {
      path: "receipt-printer-registration",
      name: STORE_ROUTER_NAMES.receiptPrinterRegistration,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s756" */ "@/views/admin/ViewS756.vue"),
    },
    {
      path: "payment-classification-setting",
      name: STORE_ROUTER_NAMES.paymentCategorySetting,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s761" */ "@/views/admin/ViewS761.vue"),
    },
    {
      path: "payment-method-setting",
      name: STORE_ROUTER_NAMES.paymentMethodSetting,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s762" */ "@/views/admin/ViewS762.vue"),
    },
    {
      path: "deposit-category-registration",
      name: STORE_ROUTER_NAMES.depositCategoryRegistration,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s771" */ "@/views/admin/ViewS771.vue"),
    },
    {
      path: "withdrawal-classification-registration",
      name: STORE_ROUTER_NAMES.withdrawalClassificationRegistration,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s772" */ "@/views/admin/ViewS772.vue"),
    },
    {
      path: "inventory-management",
      name: STORE_ROUTER_NAMES.inventoryManagement,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s781" */ "@/views/admin/ViewS781.vue"),
    },
    {
      path: "receipt-setting",
      name: STORE_ROUTER_NAMES.receiptSetting,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s782" */ "@/views/admin/ViewS782.vue"),
    },
    {
      path: "master-setting",
      name: STORE_ROUTER_NAMES.masterSetting,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s783" */ "@/views/admin/ViewS783.vue"),
    },
    {
      path: "sales-analysis",
      name: STORE_ROUTER_NAMES.salesAnalysis,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s791" */ "@/views/admin/ViewS791.vue"),
    },
    {
      path: "sales-analysis-by-product",
      name: STORE_ROUTER_NAMES.salesAnalysisByProduct,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s792" */ "@/views/admin/ViewS792.vue"),
    },
    {
      path: "sales-by-store",
      name: STORE_ROUTER_NAMES.salesByStore,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s793" */ "@/views/admin/ViewS793.vue"),
    },
    {
      path: "transaction-history/list",
      name: STORE_ROUTER_NAMES.transactionHistoryList,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s794" */ "@/views/admin/ViewS794.vue"),
    },
    {
      path: "transaction-history/detail",
      name: STORE_ROUTER_NAMES.transactionHistoryDetail,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s795" */ "@/views/admin/ViewS795.vue"),
    },
    {
      path: "transaction-history/edit",
      name: STORE_ROUTER_NAMES.transactionHistoryEdit,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s796" */ "@/views/admin/ViewS796.vue"),
    },
    {
      path: "deposit-withdrawal-history",
      name: STORE_ROUTER_NAMES.depositWithdrawalHistory,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s797" */ "@/views/admin/ViewS797.vue"),
    },
    {
      path: "cash-register-inspection-history",
      name: STORE_ROUTER_NAMES.cashRegisterInspectionHistory,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s798" */ "@/views/admin/ViewS798.vue"),
    },
    {
      path: "cash-register-inspection-history/detail",
      name: STORE_ROUTER_NAMES.cashRegisterInspectionHistoryDetail,
      meta: {
        requiresAuth: true,
        redirect: STORE_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STORE],
      },
      component: () =>
        import(/* webpackChunkName: "s799" */ "@/views/admin/ViewS799.vue"),
    }
  ],
};

export { storeRouters, STORE_ROUTER_NAMES };
