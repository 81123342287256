export const removeNull = (obj: Object): Object => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => {
        return (!!value && typeof(value) == "object" && (!!value.length || !!Object.keys(value).length)) || !!value
      })
      .map(([key, value]) => [
        key,
        value
      ]),
  );
}

export const toParams = (obj: Record<string, string>): string => {
  let params = new URLSearchParams(obj).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }

  return params;
}

export const fillForm = (targetObject: Record<string, any>, dataObject: Record<string, any>) => {
  for (let key in targetObject) {
    targetObject[key] = dataObject[key] != undefined ? dataObject[key] : null;
  }
}
