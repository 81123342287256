export const FETCH_START = "setLoading";
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";
export const RESET_STATE = "resetModuleState";
export const SET_UPDATED_DATA = "setUpdatedData";

// STORE
export const SET_LIST_STORE = "setListStore";
export const SET_LIST_STORE_PAGINATE = "setListStorePaginate";
export const SET_DETAIL_STORE = "setDetailStore";
export const SET_LIST_POS_MACHINE = "setListPosMachine";
export const SET_DETAIL_POS_MACHINE = "setDetailPosMachine";
export const SET_LIST_DRAWER = "setListDrawer";
export const SET_LIST_CASHBACK_MACHINE = "setListCashback";
export const SET_LIST_PRINT = "setListPrint";
export const SET_LIST_CATEGORY = "setListCategory";
export const SET_DETAIL_CATEGORY = "setDetailCategory";

// ADMIN
export const SET_DETAIL_COMPANY = "setDetailCompany";

//COMPANY
export const SET_LIST_COMPANY = "setListCompany";
export const SET_LIST_STAFF = "setListStaff";
export const SET_LIST_STORE_GROUP = "setListStoreGroup";
export const SET_LIST_PERMISSION = "setListPermission";
export const SET_DETAIL_PERMISSION = "setDetailPermission";
export const SET_LIST_PRODUCT = "setListProduct";
export const SET_DETAIL_PRODUCT = "setDetailProduct";
export const SET_LIST_SET_PRODUCT = "setListSetProduct";
export const SET_DETAIL_SET_PRODUCT = "setDetailSetProduct";
export const SET_LIST_BUNDLE_PRODUCT = "setListBundleProduct";
export const SET_DETAIL_BUNDLE_PRODUCT = "setDetailBundleProduct";

//SETTING
export const SET_MASTER_SETTING = "setUpdateSetting";

//PLASTIC BAG
export const SET_PLASTIC_BAGS = 'setPlasticBags';

//CATEGORY
export const SET_CREATED_CATEGORY = "setCreatedCategoryRoot";
export const SET_UPDATED_CATEGORY = "setCreatedCategory";

export const SET_LOADING_UPDATE_CATEGORY = "loadingUpdateCategory";
