<template>
  <div class="company-container">
    <SideBar :sidebar-title="sidebarTitle" :display-note="displayNote" />
    <div class="company-container__content">
      <HeaderAdmin
        :page-title="pageTitle"
        :display-logo="displayLogo"
        :display-printer="displayPrinter"
        :display-reload="displayReload"
      />
      <slot name="content"></slot>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="js">
import SideBar from './Sidebar.vue';
import HeaderAdmin from '../admin/Header.vue';

export default {
  components: {
    SideBar,
    HeaderAdmin
  },
  props: {
    pageTitle: String,
    sidebarTitle: {
      type: String,
      default: "とどろく",
    },
    displayNote: {
      type: Boolean,
      default: true,
    },
    displayLogo: {
      type: Boolean,
      default: true,
    },
    displayPrinter: {
      type: Boolean,
      default: false,
    },
    displayReload: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.company-container {
  display: flex;
  height: 100vh;
  margin: auto;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
</style>
