<template>
  <div class="container--common">
    <HeaderView
      :title="pageTitle"
      :display-button="displayButton"
      :display-step="displayStep"
    />
    <slot name="content"></slot>
    <slot name="footer"></slot>
  </div>
</template>

<script lang="js">
import HeaderView from "@/components/layouts/HeaderView.vue";

export default {
  components: {
    HeaderView,
  },
  props: {
    pageTitle: String,
    displayButton: Boolean,
    displayStep: Boolean,
  }
}
</script>
