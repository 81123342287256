import { PATH_URL } from "@/constants";
import ApiInstance from "./axiosBase";
import { toParams } from "@/utils/ObjectUtils";
import { ICreateOrUpdateStaff } from "./models/ICreateStaff";
import { IUpdateDisplay } from "./models/IDisplay";

export const GetListStoreGroup = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/store-group/list` + params);
};

export const UpdateDisplayStoreGroup = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/store-group/update/display`, body);
};

export const DeleteStoreGroup = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.STORE}/store-group/delete/${id}`);
};

export const GetListPermission = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.COMPANY}/permission/list` + params);
};

export const UpdateDisplayPermission = (body: any) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/permission/create`, body);
};

export const DeletePermission = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.COMPANY}/permission/delete/${id}`);
};

export const EditPermission = (id: number) => {
  return ApiInstance.get(`${PATH_URL.COMPANY}/permission/${id}`);
}

export const UpdatePermission = (body: any, id: number) => {
  return ApiInstance.put(`${PATH_URL.COMPANY}/permission/${id}`, body);
}

// Staff
export const GetListStaff = (query: { keyword: string, role: string }) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.COMPANY}/staff/list` + params);
};

export const CreateStaff = (body: ICreateOrUpdateStaff) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/staff/create`, body);
};

export const UpdateStaff = (body: ICreateOrUpdateStaff, id: number) => {
  return ApiInstance.put(`${PATH_URL.COMPANY}/staff/update/${id}`, body);
};

export const DeleteStaff = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.COMPANY}/staff/delete/${id}`);
};

export const UpdateListStaffDisplay = (body: IUpdateDisplay[]) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/staff/display/update`, body);
};

export const UpdateStaffStatus = (body: { ids: number[], statusId: number }) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/staff/status/update`, body);
};

export const DownloadCSV = () => {
  ApiInstance.get(`${PATH_URL.COMPANY}/staff/export-csv`, { responseType: 'blob' }).then((response: any) => {
    const href = window.URL.createObjectURL(response);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'スタッフ作成用テンプレート.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export const CompanyImportStaff = (body: ICreateOrUpdateStaff[]) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/staff/import`, body);
};

export const GetPlasticBags = () => {
  return ApiInstance.get(`${PATH_URL.COMPANY}/plastic-bag/list`);
}

export const DeletePlasticBags = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.COMPANY}/plastic-bag/delete/${id}`);
}

export const UpdateDisplayPlasticBags = (body: any) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/plastic-bag/display/update`, body);
}

export const CreateProduct = (body: any) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/product/create`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export const GetListProduct = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.COMPANY}/product/list` + params);
};

export const DeleteProduct = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.COMPANY}/product/delete/${id}`);
};

export const UpdateDisplayProduct = (body: any) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/product/display/update`, body);
};

export const DetailProduct = (id: number) => {
  return ApiInstance.get(`${PATH_URL.COMPANY}/product/${id}`);
};

export const UpdateProduct = (body: any) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/product/create`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const GetListSetProduct = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.COMPANY}/set-product/list` + params);
};

export const DeleteSetProduct = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.COMPANY}/set-product/delete/${id}`);
};

export const DetailSetProduct = (id: number) => {
  return ApiInstance.get(`${PATH_URL.COMPANY}/set-product/${id}`);
};

export const UpdateDisplaySetProduct = (body: any) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/set-product/display/update`, body);
};

export const CreateSetProduct = (body: any) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/set-product/create`, body);
};

export const UpdateSetProduct = (body: any) => {
  return ApiInstance.put(`${PATH_URL.COMPANY}/set-product/update/${body.id}`, body);
};

export const DownloadCSVSetProduct = () => {
  ApiInstance.get(`${PATH_URL.COMPANY}/set-product/export-csv`, { responseType: 'blob' }).then((response: any) => {
    const href = window.URL.createObjectURL(response);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', '商品セット作成テンプレート.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export const GetListBundleProduct = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.COMPANY}/bundle-product/list` + params);
};

export const DeleteBundleProduct = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.COMPANY}/bundle-product/delete/${id}`);
};

export const UpdateDisplayBundleProduct = (body: any) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/bundle-product/display/update`, body);
};

export const DownloadCSVBundleProduct = () => {
  ApiInstance.get(`${PATH_URL.COMPANY}/bundle-product/export-csv`, { responseType: 'blob' }).then((response: any) => {
  });
};

export const DetailBundleProduct = (id: number) => {
  return ApiInstance.get(`${PATH_URL.COMPANY}/bundle-product/${id}`);
};

export const UpdateBundleProduct = (body: any) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/bundle-product/update/${body.id}`, body);
};
