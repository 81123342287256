import PassThroughVue from "@/components/layouts/PassThrough.vue";
import RoutesNames from "./routesNames";
import { ACCOUNT_TYPES } from "@/constants";

const ADMIN_ROUTER_NAMES = {
  home: RoutesNames.admin[1],
  login: RoutesNames.admin[0],
  passwordResetMailSent: "passwordResetMailSent",
  passwordResetMailSentCompleted: "passwordResetMailSentCompleted",
  passwordReset: "passwordReset",
  companyResetPassword: "companyResetPassword",
  passwordResetCompleted: "passwordResetCompleted",
  errorEmailExpired: "errorEmailExpired",
  errorEmailAlreadySet: "errorEmailAlreadySet",
  dashboard: "dashboard",
  storeDetail: "storeDetail",
  accountSetting: "accountSetting",
  changePassword: "changePassword",
  masterSetting: "masterSetting",
  companyList: "companyList",
  companyDetail: "companyDetail",
  companyCreate: "companyCreate",
  billingList: "billingList",
};

const adminCompanyRouter = {
  path: "/company",
  name: "company_page",
  component: PassThroughVue,
  children: [
    {
      path: "reset-password",
      name: ADMIN_ROUTER_NAMES.companyResetPassword,
      meta: {
        anonymousOnly: true,
        redirect: ADMIN_ROUTER_NAMES.home,
      },
      component: () =>
        import(/* webpackChunkName: "s904" */ "@/views/auth/ViewS904.vue"),
    },
  ]
}

const adminRouters =
{
  path: "/admin",
  name: "admin_pages",
  component: PassThroughVue,
  children: [
    {
      path: "",
      name: ADMIN_ROUTER_NAMES.login,
      meta: {
        anonymousOnly: true,
        redirect: RoutesNames.admin[1],
      },
      component: () =>
        import(/* webpackChunkName: "s901" */ "@/views/auth/ViewS901.vue"),
    },
    {
      path: "password-reset/mail",
      name: ADMIN_ROUTER_NAMES.passwordResetMailSent,
      meta: {
        anonymousOnly: true,
        redirect: ADMIN_ROUTER_NAMES.home,
      },
      component: () =>
        import(/* webpackChunkName: "s902" */ "@/views/auth/ViewS902.vue"),
    },
    {
      path: "password-reset/mail-completed",
      name: ADMIN_ROUTER_NAMES.passwordResetMailSentCompleted,
      meta: {
        anonymousOnly: true,
        redirect: ADMIN_ROUTER_NAMES.home,
      },
      component: () =>
        import(/* webpackChunkName: "s903" */ "@/views/auth/ViewS903.vue"),
    },
    {
      path: "reset-password",
      name: ADMIN_ROUTER_NAMES.passwordReset,
      meta: {
        anonymousOnly: true,
        redirect: ADMIN_ROUTER_NAMES.home,
      },
      component: () =>
        import(/* webpackChunkName: "s904" */ "@/views/auth/ViewS904.vue"),
    },
    {
      path: "password-reset/completed",
      name: ADMIN_ROUTER_NAMES.passwordResetCompleted,
      meta: {
        anonymousOnly: true,
        redirect: ADMIN_ROUTER_NAMES.home,
      },
      component: () =>
        import(/* webpackChunkName: "s905" */ "@/views/auth/ViewS905.vue"),
    },
    {
      path: "error-email-expired",
      name: ADMIN_ROUTER_NAMES.errorEmailExpired,
      meta: {
        anonymousOnly: true,
        redirect: ADMIN_ROUTER_NAMES.home,
      },
      component: () =>
        import(/* webpackChunkName: "s906" */ "@/views/auth/ViewS906.vue"),
    },
    {
      path: "error-email-already-set",
      name: ADMIN_ROUTER_NAMES.errorEmailAlreadySet,
      meta: {
        anonymousOnly: true,
        redirect: ADMIN_ROUTER_NAMES.home,
      },
      component: () =>
        import(/* webpackChunkName: "s907" */ "@/views/auth/ViewS907.vue"),
    },
    {
      path: "home",
      name: ADMIN_ROUTER_NAMES.home,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.admin[0],
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
      },
      component: () =>
        import(/* webpackChunkName: "s911" */ "@/views/admin/ViewS911.vue"),
    },
    {
      path: "store/:storeId",
      name: ADMIN_ROUTER_NAMES.storeDetail,
      meta: {
        requiresAuth: true,
        redirect: ADMIN_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
      },
      component: () =>
        import(/* webpackChunkName: "s912" */ "@/views/admin/ViewS912.vue"),
    },
    {
      path: "account-setting",
      name: ADMIN_ROUTER_NAMES.accountSetting,
      meta: {
        requiresAuth: true,
        redirect: ADMIN_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
      },
      component: () =>
        import(/* webpackChunkName: "s921" */ "@/views/admin/ViewS921.vue"),
    },
    {
      path: "account-setting/change-password",
      name: ADMIN_ROUTER_NAMES.changePassword,
      meta: {
        requiresAuth: true,
        redirect: ADMIN_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
      },
      component: () =>
        import(/* webpackChunkName: "s922" */ "@/views/admin/ViewS922.vue"),
    },
    {
      path: "setting",
      name: ADMIN_ROUTER_NAMES.masterSetting,
      meta: {
        requiresAuth: true,
        redirect: ADMIN_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
      },
      component: () =>
        import(/* webpackChunkName: "s931" */ "@/views/admin/ViewS931.vue"),
    },
    {
      path: "company",
      name: ADMIN_ROUTER_NAMES.companyList,
      meta: {
        requiresAuth: true,
        redirect: ADMIN_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
      },
      component: () =>
        import(/* webpackChunkName: "s951" */ "@/views/admin/ViewS951.vue"),
    },
    {
      path: "company/:companyId",
      name: ADMIN_ROUTER_NAMES.companyDetail,
      meta: {
        requiresAuth: true,
        redirect: ADMIN_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
      },
      component: () =>
        import(/* webpackChunkName: "s952" */ "@/views/admin/ViewS952.vue"),
    },
    {
      path: "company/create",
      name: ADMIN_ROUTER_NAMES.companyCreate,
      meta: {
        requiresAuth: true,
        redirect: ADMIN_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
      },
      component: () =>
        import(/* webpackChunkName: "s953" */ "@/views/admin/ViewS953.vue"),
    },
    {
      path: "top/billing-list",
      name: ADMIN_ROUTER_NAMES.billingList,
      meta: {
        requiresAuth: true,
        redirect: ADMIN_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.SYSTEM_ADMIN],
      },
      component: () =>
        import(/* webpackChunkName: "s954" */ "@/views/admin/ViewS954.vue"),
    },
  ],
};

export { adminRouters, ADMIN_ROUTER_NAMES, adminCompanyRouter };
