import { ACCOUNT_TYPES, LANGUAGES, REGEX_ONLY_NUMBER, STAFF_ROLES, TYPE_CONDITION_SET_PRICE } from "@/constants";
import routesNames from "@/router/routesNames";
import { notify } from "@kyvg/vue3-notification";

export const handleRedirect = (user: any, login: boolean) => {
  switch (user?.role) {
    case ACCOUNT_TYPES.SYSTEM_ADMIN:
      return !login ? routesNames.admin[0] : routesNames.admin[1];
    case ACCOUNT_TYPES.COMPANY_MANAGER:
    case ACCOUNT_TYPES.COMPANY_STAFF:
      return !login ? routesNames.company[0] : routesNames.company[1];
    case ACCOUNT_TYPES.STORE:
      return !login ? routesNames.store[0] : routesNames.store[1];
    default:
      return !login ? routesNames.customer[0] : routesNames.customer[1];
  }
};

export const currencyFormatted = (num: any) => {
  return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0;
};

function isValidGoogleMapsUrl(url: string) {
  if (!url) return;
  const regex = /^(https?:\/\/)?(www\.)?google\.com\/maps\/.*$/;
  return regex.test(url);
}

export const getLatLongFromUrl = (url: string) => {
  if (!isValidGoogleMapsUrl(url)) return;
  const longlat = /\/\@(.*),(.*),/.exec(url);
  return {
    latitude: longlat?.[1],
    longitude: longlat?.[2],
  }
};

export const formatCSVData = (csvData: any, dataMapping: any) => {
  return csvData.map((record: any) => {
    const formattedData: any = {};
    for (const key in dataMapping) {
      if (key === 'role') {
        const isAdmin = record[dataMapping[key]] === STAFF_ROLES.ADMIN;
        formattedData[key] = isAdmin ? ACCOUNT_TYPES.COMPANY_MANAGER : ACCOUNT_TYPES.COMPANY_STAFF;
      } else {
        formattedData[key] = record[dataMapping[key]];
      }
    }
    return formattedData;
  });
};

export const acceptOnlyNumeric = (value: number | string) => {
  return value.toString().replace(REGEX_ONLY_NUMBER, '');
}

export function validateElementNames(elements: any[], property: string): string[] {
  if (!elements) {
    return [];
  }

  const errors = [];
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i][property];
    if (element.length > 255) {
      errors[i] = "255文字以内で入力してください。";
    }
  }
  return errors;
}

export const getDefaultNameCategory = (category: { categoryLanguages: Array<any> }) => {
  return category.categoryLanguages.find((item) => item.language === LANGUAGES.JA.value)?.name;
}

export const getKeyLanguage = (key: number) => {
  return Object.values(LANGUAGES).find((item) => item.value === key)?.key;
}

export const convertBytesToMegabytes = (bytes: number) => {
  return (bytes / 1024 / 1024).toFixed(2);
}

export const toastError = (error: string, title: string = '') => {
  notify({
    type: "error",
    title: title,
    text: error || '',
  })
}

export const formatNumber = (number: number | string) => {
  if (!String(number).length || number === null || number === undefined) {
    return null;
  }
  return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const getRawNumber = (number: string) => {
  if (!String(number).length || number === null || number === undefined) {
    return null;
  }
  return Number(number.toString().replace(/,/g, ''));
}

export const getCategoryItem = (
  categoryAndProductPivot: Array<any>,
  level: number
) => {
  return categoryAndProductPivot?.find(
    (item) => item.category.level === level
  )?.category?.id;
}
