import { PATH_URL } from "@/constants";
import ApiInstance from "./axiosBase";

export const AdminCreateStore = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/create`, body);
};

export const AdminCreateCompany = (body: any) => {
  return ApiInstance.post(`${PATH_URL.COMPANY}/create`, body);
};

export const AdminDetailCompany = (id: any) => {
  return ApiInstance.get(`${PATH_URL.COMPANY}/${id}`);
};

export const AdminUpdateCompany = (data: any) => {
  if (!data?.id) return ApiInstance.post(`${PATH_URL.COMPANY}/create`, data);
  return ApiInstance.put(`${PATH_URL.COMPANY}/update/${data.id}`, data);
};

export const AdminGetListStore = (body: {page: number, keyword: string}) => {
  return ApiInstance.get(`${PATH_URL.COMPANY}/list`, {params: body});
}
