import auth from "./auth.module";
import admin from "./admin.module";
import store from "./store.module";
import company from "./company.module";
import setting from "./setting.module";
export default {
  auth,
  admin,
  store,
  company,
  setting
};
