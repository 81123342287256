import PassThroughVue from "@/components/layouts/PassThrough.vue";
import RoutesNames from "./routesNames";
import { ACCOUNT_TYPES } from "@/constants";

const STAFF_ROUTER_NAMES = {
  home: RoutesNames.customer[1],
  login: RoutesNames.customer[0],
  settingLogin: "settingLogin",
  menu: "menu",
  modalRFIDIntoBox: "modalRFIDIntoBox",
  RFIDTagWriting: "RFIDTagWriting",
  modalSystemErrorSaleProhibitedError: "modalSystemErrorSaleProhibitedError",
  RFIDTagWritingSearch: "RFIDTagWritingSearch",
  RFIDTagWritingJanCode: "RFIDTagWritingJanCode",
  RFIDTagWritingConfirmWriteProduct: "RFIDTagWritingConfirmWriteProduct",
  RFIDTagWritingWaitWritingCompleted: "RFIDTagWritingWaitWritingCompleted",
  RFIDTagWritingCompleted: "RFIDTagWritingCompleted",
  staffCart: "staffCart",
  staffCartKeypad: "staffCartKeypad",
  staffProduct: "staffProduct",
  staffJanCode: "staffJanCode",
  staffPaymentCashless: "staffPaymentCashless",
  staffAmountEntry: "staffAmountEntry",
  staffCouponSelection: "staffCouponSelection",
  staffPaymentInCash: "staffPaymentInCash",
  staffPaymentInCashProcessing: "staffPaymentInCashProcessing",
  staffRegisterCreditCard: "staffRegisterCreditCard",
  staffRegisterCreditCardContinue: "staffRegisterCreditCardContinue",
  staffRegisterCreditCardProcessing: "staffRegisterCreditCardProcessing",
  staffRegisterCreditCardCompleted: "staffRegisterCreditCardCompleted",
  staffElectronicMoneySelection: "staffElectronicMoneySelection",
  staffElectronicMoneyPayment: "staffElectronicMoneyPayment",
  staffQRCodePayment: "staffQRCodePayment",
  staffQRCodePaymentWith: "staffQRCodePaymentWith",
  staffReceiptPrintingAllTypes: "staffReceiptPrintingAllTypes",
  staffPaymentCompleted: "staffPaymentCompleted",
  returnProcessing: "returnProcessing",
  modalReturnProcessing: "modalReturnProcessing",
  refundMethodSelection: "refundMethodSelection",
  cancellationProcessing: "cancellationProcessing",
  cancellationProcessingWithCreditCard: "cancellationProcessingWithCreditCard",
  cancellationProcessingWithElectronicMoney: "cancellationProcessingWithElectronicMoney",
  cancellationProcessingWithQRcode: "cancellationProcessingWithQRcode",
  modalTransactionCancellation: "modalTransactionCancellation",
  cancellationReceiptPrinting: "cancellationReceiptPrinting",
  cancellationProcessingCompleted: "cancellationProcessingCompleted",
  transactionHistorySearch: "transactionHistorySearch",
  returnProcessingMaintenance: "returnProcessingMaintenance",
  returnProcessForReturnedItems: "returnProcessForReturnedItems",
  enterChangeReserve: "enterChangeReserve",
  modalEnterChangeReserve: "modalEnterChangeReserve",
  cashRegisterInspection: "cashRegisterInspection",
  modalCashRegisterInspection: "modalCashRegisterInspection",
  cashRegisterClose: "cashRegisterClose",
  modalCashRegisterClose: "modalCashRegisterClose",
  modalDepositProcess: "modalDepositProcess",
  modalDepositCompleted: "modalDepositCompleted",
  modalWithdrawalProcess: "modalWithdrawalProcess",
  modalWithdrawalCompleted: "modalWithdrawalCompleted",
};

const staffRouters = {
  path: "/staff",
  name: "staff_pages",
  component: PassThroughVue,
  children: [
    {
      path: "setting/login",
      name: STAFF_ROUTER_NAMES.settingLogin,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t201" */ "@/views/client/ViewT201.vue"),
    },
    {
      path: "menu",
      name: STAFF_ROUTER_NAMES.menu,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t202" */ "@/views/client/ViewT202.vue"),
    },
    {
      path: "modalRFIDIntoBox",
      name: STAFF_ROUTER_NAMES.modalRFIDIntoBox,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t211" */ "@/views/client/ViewT211.vue"),
    },
    {
      path: "RFID-tag-writing",
      name: STAFF_ROUTER_NAMES.RFIDTagWriting,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t212" */ "@/views/client/ViewT212.vue"),
    },
    {
      path: "modalSystemError/sales-prohibited-error",
      name: STAFF_ROUTER_NAMES.modalSystemErrorSaleProhibitedError,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t212-2" */ "@/views/client/ViewT212-2.vue"),
    },
    {
      path: "RFID-tag-writing/search",
      name: STAFF_ROUTER_NAMES.RFIDTagWritingSearch,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t213" */ "@/views/client/ViewT213.vue"),
    },
    {
      path: "RFID-tag-writing/jan-code",
      name: STAFF_ROUTER_NAMES.RFIDTagWritingJanCode,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t214-1" */ "@/views/client/ViewT214-1.vue"),
    },
    {
      path: "RFID-tag-writing/confirm-write-product",
      name: STAFF_ROUTER_NAMES.RFIDTagWritingConfirmWriteProduct,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t221" */ "@/views/client/ViewT221.vue"),
    },
    {
      path: "RFID-tag-writing/wait-writing-completed",
      name: STAFF_ROUTER_NAMES.RFIDTagWritingWaitWritingCompleted,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t222" */ "@/views/client/ViewT222.vue"),
    },
    {
      path: "RFID-tag-writing/completed",
      name: STAFF_ROUTER_NAMES.RFIDTagWritingCompleted,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t223" */ "@/views/client/ViewT223.vue"),
    },
    {
      path: "cart",
      name: STAFF_ROUTER_NAMES.staffCart,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t301" */ "@/views/client/ViewT301.vue"),
    },
    {
      path: "cart/keypad",
      name: STAFF_ROUTER_NAMES.staffCartKeypad,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(
            /* webpackChunkName: "t301-2" */ "@/views/client/ViewT301-2.vue"
        ),
    },
    {
      path: "product",
      name: STAFF_ROUTER_NAMES.staffProduct,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t311" */ "@/views/client/ViewT311.vue"),
    },
    {
      path: "jan-code",
      name: STAFF_ROUTER_NAMES.staffJanCode,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t312" */ "@/views/client/ViewT312.vue"),
    },
    {
      path: "payment/cashless",
      name: STAFF_ROUTER_NAMES.staffPaymentCashless,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t321" */ "@/views/client/ViewT321.vue"),
    },
    {
      path: "amount-entry",
      name: STAFF_ROUTER_NAMES.staffAmountEntry,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t322" */ "@/views/client/ViewT322.vue"),
    },
    {
      path: "coupon/selection",
      name: STAFF_ROUTER_NAMES.staffCouponSelection,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t331" */ "@/views/client/ViewT331.vue"),
    },
    {
      path: "payment-in-cash",
      name: STAFF_ROUTER_NAMES.staffPaymentInCash,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t341" */ "@/views/client/ViewT341.vue"),
    },
    {
      path: "payment-in-cash/processing",
      name: STAFF_ROUTER_NAMES.staffPaymentInCashProcessing,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t342" */ "@/views/client/ViewT342.vue"),
    },
    {
      path: "register-credit-card",
      name: STAFF_ROUTER_NAMES.staffRegisterCreditCard,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t351" */ "@/views/client/ViewT351.vue"),
    },
    {
      path: "register-credit-card/continue",
      name: STAFF_ROUTER_NAMES.staffRegisterCreditCardContinue,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t352" */ "@/views/client/ViewT352.vue"),
    },
    {
      path: "register-credit-card/processing",
      name: STAFF_ROUTER_NAMES.staffRegisterCreditCardProcessing,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t353" */ "@/views/client/ViewT353.vue"),
    },
    {
      path: "register-credit-card/completed",
      name: STAFF_ROUTER_NAMES.staffRegisterCreditCardCompleted,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t354" */ "@/views/client/ViewT354.vue"),
    },
    {
      path: "electronic-money/selection",
      name: STAFF_ROUTER_NAMES.staffElectronicMoneySelection,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t361" */ "@/views/client/ViewT361.vue"),
    },
    {
      path: "electronic-money/payment",
      name: STAFF_ROUTER_NAMES.staffElectronicMoneyPayment,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t362" */ "@/views/client/ViewT362.vue"),
    },
    {
      path: "qr-code/payment",
      name: STAFF_ROUTER_NAMES.staffQRCodePayment,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t371" */ "@/views/client/ViewT371.vue"),
    },
    {
      path: "qr-code/payment-with",
      name: STAFF_ROUTER_NAMES.staffQRCodePaymentWith,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t372" */ "@/views/client/ViewT372.vue"),
    },
    {
      path: "receipt-printing/all-types",
      name: STAFF_ROUTER_NAMES.staffReceiptPrintingAllTypes,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t381" */ "@/views/client/ViewT381.vue"),
    },
    {
      path: "payment/completed",
      name: STAFF_ROUTER_NAMES.staffPaymentCompleted,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t382" */ "@/views/client/ViewT382.vue"),
    },
    {
      path: "return-processing",
      name: STAFF_ROUTER_NAMES.returnProcessing,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t501" */ "@/views/client/ViewT501.vue"),
    },
    {
      path: "modalReturnProcessing",
      name: STAFF_ROUTER_NAMES.modalReturnProcessing,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t502" */ "@/views/client/ViewT502.vue"),
    },
    {
      path: "return-method/selection",
      name: STAFF_ROUTER_NAMES.refundMethodSelection,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t511" */ "@/views/client/ViewT511.vue"),
    },
    {
      path: "cancellation-processing",
      name: STAFF_ROUTER_NAMES.cancellationProcessing,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t512" */ "@/views/client/ViewT512.vue"),
    },
    {
      path: "cancellation-processing/credit-card",
      name: STAFF_ROUTER_NAMES.cancellationProcessingWithCreditCard,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t513" */ "@/views/client/ViewT513.vue"),
    },
    {
      path: "cancellation-processing/electronic-money",
      name: STAFF_ROUTER_NAMES.cancellationProcessingWithElectronicMoney,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t514" */ "@/views/client/ViewT514.vue"),
    },
    {
      path: "cancellation-processing/qr-code",
      name: STAFF_ROUTER_NAMES.cancellationProcessingWithQRcode,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t515" */ "@/views/client/ViewT515.vue"),
    },
    {
      path: "modalTransactionCancellation",
      name: STAFF_ROUTER_NAMES.modalTransactionCancellation,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t516" */ "@/views/client/ViewT516.vue"),
    },
    {
      path: "cancellation-receipt-printing",
      name: STAFF_ROUTER_NAMES.cancellationReceiptPrinting,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t517" */ "@/views/client/ViewT517.vue"),
    },
    {
      path: "cancellation-processing/completed",
      name: STAFF_ROUTER_NAMES.cancellationProcessingCompleted,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t518" */ "@/views/client/ViewT518.vue"),
    },
    {
      path: "transaction-history",
      name: STAFF_ROUTER_NAMES.transactionHistorySearch,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t521" */ "@/views/client/ViewT521.vue"),
    },
    {
      path: "return-processing/maintenance",
      name: STAFF_ROUTER_NAMES.returnProcessingMaintenance,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t531" */ "@/views/client/ViewT531.vue"),
    },
    {
      path: "return-processing/returned-items",
      name: STAFF_ROUTER_NAMES.returnProcessForReturnedItems,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t532" */ "@/views/client/ViewT532.vue"),
    },
    {
      path: "enter-change-reserve",
      name: STAFF_ROUTER_NAMES.enterChangeReserve,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t601" */ "@/views/client/ViewT601.vue"),
    },
    {
      path: "modalEnterChangeReserve",
      name: STAFF_ROUTER_NAMES.modalEnterChangeReserve,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t602" */ "@/views/client/ViewT602.vue"),
    },
    {
      path: "cash-register/inspection",
      name: STAFF_ROUTER_NAMES.cashRegisterInspection,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t603" */ "@/views/client/ViewT603.vue"),
    },
    {
      path: "modalCashRegisterInspection",
      name: STAFF_ROUTER_NAMES.modalCashRegisterInspection,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t604" */ "@/views/client/ViewT604.vue"),
    },
    {
      path: "cash-register/close",
      name: STAFF_ROUTER_NAMES.cashRegisterClose,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t605" */ "@/views/client/ViewT605.vue"),
    },
    {
      path: "modalCashRegisterClose",
      name: STAFF_ROUTER_NAMES.modalCashRegisterClose,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t606" */ "@/views/client/ViewT606.vue"),
    },
    {
      path: "modalDepositProcess",
      name: STAFF_ROUTER_NAMES.modalDepositProcess,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t611" */ "@/views/client/ViewT611.vue"),
    },
    {
      path: "modalDepositCompleted",
      name: STAFF_ROUTER_NAMES.modalDepositCompleted,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t612" */ "@/views/client/ViewT612.vue"),
    },
    {
      path: "modalWithdrawalProcess",
      name: STAFF_ROUTER_NAMES.modalWithdrawalProcess,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t613" */ "@/views/client/ViewT613.vue"),
    },
    {
      path: "modalWithdrawalCompleted",
      name: STAFF_ROUTER_NAMES.modalWithdrawalCompleted,
      meta: {
        requiresAuth: true,
        redirect: STAFF_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.CUSTOMER],
      },
      component: () =>
        import(/* webpackChunkName: "t614" */ "@/views/client/ViewT614.vue"),
    },
  ],
};

export { staffRouters, STAFF_ROUTER_NAMES };
